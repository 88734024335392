<template>
  <div>
    <a-table
      @change="changePage"
      :pagination="pagination"
      :loading="loading"
      :columns="columns"
      :data-source="list"
      :rowKey="(record) => record.id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
<!-- id -->
      <div slot="id" slot-scope="row">
        <a-popover title="参数">
          <template slot="content">
            <a-descriptions bordered title="" size="small">
              <a-descriptions-item
                v-for="(item, index) in row.wptExtraInfo"
                :key="index"
                :label="item.typeName"
              >
                {{ item.value ? item.value : "-" }}
              </a-descriptions-item>
            </a-descriptions>
          </template>
          {{ row.id }}
        </a-popover>
      </div>
<!--   评级相关   -->
      <div slot="companyInfoSlot" slot-scope="row">
        <div class="" v-if="row.certNumber">评级编号：{{ row.certNumber }}</div>
        <div class="" v-if="row.companyType">评级公司：{{ computedRatingCompanyTypeListMapText(row.companyType) }}</div>
        <div class="" v-if="row.score">分数：{{ row.score }}</div>
      </div>
<!-- 名称 -->
      <div slot="title" slot-scope="row">
        <a-popover title="内容">
          <template slot="content">
            <div class="title-content" v-html="row.content"></div>
          </template>
          {{ row.title }}
        </a-popover>
      </div>
<!-- 封面 -->
      <div slot="Images" slot-scope="row">
        <template v-if="row.isConfirm">
          <div v-if="row.coverList && row.coverList.length" class="w-100-w flex-wrap flex-start-center">
            <div class="img-box-item w-100 h-100" v-for="(item, index) in row.coverList.slice(0, 6)" :key="item">
              <img class="w-100 h-100" @click="previewImg(row, index, 'cover')" :src="item + '/resize,w_200'" alt=""/>
              <a-popconfirm
                  title="确定删除当前图片吗？"
                  ok-text="是"
                  cancel-text="取消"
                  @confirm="handleQuickDelImg(row, index, row.coverList.slice(0, 6), 'cover')"
              >
                <a-icon class="quick-del-icon font-weight-bold cur-pot" type="close-circle" size="20" color="red" />
              </a-popconfirm>
            </div>
          </div>
          <img v-else style="max-width: 100px; max-height: 100px" src="http://image.douquan.com/static/product-default.png" alt=""/>
        </template>
        <template v-else>
          <div v-if="row.picturesList && row.picturesList.length" class="w-100-w flex-wrap flex-start-center">
            <div class="img-box-item w-100 h-100" v-for="(item, index) in row.picturesList.slice(0, 6)" :key="item">
              <img class="w-100 h-100" @click="previewImg(row, index, 'pic')" :src="item + '/resize,w_200'" alt=""/>
              <a-popconfirm
                  title="确定删除当前图片吗？"
                  ok-text="是"
                  cancel-text="取消"
                  @confirm="handleQuickDelImg(row, index, row.picturesList.slice(0, 6), 'pic')"
              >
                <a-icon class="quick-del-icon font-weight-bold cur-pot" type="close-circle" size="20" color="red" />
              </a-popconfirm>
            </div>
          </div>
          <img v-else style="max-width: 100px; max-height: 100px" src="http://image.douquan.com/static/product-default.png" alt=""/>
        </template>
      </div>
<!--  数据来源    -->
      <div slot="itemDataSource" slot-scope="row">
        <div class="color-blue">{{ computedDataSourceTypeListMapText(row.type) }}</div>
        <div v-if="row.type === 3" :class="productWptStatusTypeClass[row.wptStatus]">{{ computedWptStatusTypeListMapText(row.wptStatus) }}</div>
        <div class="mt-10 flex-start-center">
          是否已经确认：
          <div :class="isConfirmStatusClassType[row.isConfirm]">{{ computedIsConfirmStatusTypeListMapText(row.isConfirm) }}</div>
        </div>
        <div class="mt-10 flex-start-center">
          是否真实：
          <div :class="row.isReal === 1 ? 'color-green' : 'color-red'">{{ row.isReal === 1 ? '真实' : '不确定' }}</div>
        </div>
      </div>
<!-- 图片 -->
       <div slot="size-weight" slot-scope="row">
         {{row.size}}/{{row.weight}}
       </div>
      <div slot="setup" slot-scope="row">
        <a-button
          type="primary"
          size="small"
          @click="editItem(row)"
          style="margin: 5px"
          >编辑</a-button>
        <a-popconfirm
            title="确认数据？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="removeClick(row.id, 'confirm')"
        >
          <a-button v-if="!row.isConfirm" size="small" type="primary" style="margin: 5px">确认</a-button>
        </a-popconfirm>
        <a-popconfirm
          title="确定删除当前数据？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="removeClick(row.id, 'del')"
        >
          <a-button size="small" type="danger" style="margin: 5px">删除</a-button>
        </a-popconfirm>
      </div>
    </a-table>
  </div>
</template>

<script>
import {
  computedDataSourceTypeList, computedIsConfirmStatusTypeList,
  computedRatingCompanyTypeList,
  computedWptStatusTypeList, IS_CONFIRM_STATUS_CLASS_TYPE,
  PRODUCT_WPT_STATUS_TYPE_CLASS
} from "@/_data"
import {spiderDeleteImg} from "@/views/cmsPage/coinManage/_apis"
export default {
  props: ["list", "loading", "pagination"],
  computed: {
    computedRatingCompanyTypeListMapText() {
      return value => computedRatingCompanyTypeList(value)
    },
    computedDataSourceTypeListMapText() {
      return value => computedDataSourceTypeList(value)
    },
    computedWptStatusTypeListMapText() {
      return value => computedWptStatusTypeList(value)
    },
    computedIsConfirmStatusTypeListMapText() {
      return value => computedIsConfirmStatusTypeList(value)
    },
  },
  data() {
    return {
      isConfirmStatusClassType: IS_CONFIRM_STATUS_CLASS_TYPE,
      selectedRowKeys: [],
      productWptStatusTypeClass: PRODUCT_WPT_STATUS_TYPE_CLASS,
      columns: [
        { title: "ID", scopedSlots: { customRender: "id" }},
        { title: "商品名称", scopedSlots: { customRender: "title" }},
        { title: "评级相关", scopedSlots: { customRender: "companyInfoSlot" }},
        { title: "价格", dataIndex: "price" },
        { title: "图片", scopedSlots: { customRender: "Images" }, align: 'center', width: 700 },
        { title: "尺寸/重量", scopedSlots: { customRender: "size-weight" } },
        {title: "数据源", scopedSlots: { customRender: "itemDataSource"}, width: 200, align: 'center'},
        { title: "成交时间", dataIndex: "tradeTime", customRender: (text) => {
            return <span>{text}</span>;
          }
        },
        { title: "操作", align: "center", scopedSlots: { customRender: "setup" } },
      ],
    };
  },
  watch: {
    list: {
      handler() {
        this.selectedRowKeys = [];
        this.$emit("selectTable", []);
      },
      deep: true
    }
  },
  methods: {
    /** 快速删除当前图片 */
    async handleQuickDelImg(row, index, imgList, type) {
      const foundIndex = imgList[index].indexOf('?')
      const tempImgUrl = imgList[index].slice(0, foundIndex)
      const res = await spiderDeleteImg({id: row.id, image: tempImgUrl})
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.$emit('delImg', {
        id: row.id,
        index,
        type
      })
    },
    /** table选中 */
    onSelectChange(keys, arr) {
      this.selectedRowKeys = keys;
      this.$emit("selectTable", arr);
    },
    // 更新封面
    async updateImage(row) {
      this.$loading.show();
      const res = await this.axios("/dq_admin/qianbiGoodsSplider/updateCover", {
        params: {
          id: row.id,
        }}
      );
      this.$loading.hide();
      if (res.status !== '200') return this.$message.error(res.message);
      this.$message.success(res.message);
      this.$emit("success");
    },
    // 编辑
    editItem(item) {
      this.$emit("editItem", item);
    },
    // 删除
    async actionEvent(id, url) {
      const res = await this.axios(url, {params: {ids: id,}});
      if (res.status !== '200') return;
      this.$message.success("操作成功");
      this.$emit("success");
    },
    // 删除点击
    async removeClick(id, type) {
      const apiUrl = type === 'del' ? '/dq_admin/qianbiGoodsSplider/delById' : '/dq_admin/qianbiGoodsSplider/confirmDatas'
      await this.actionEvent(id, apiUrl);
    },

    // 切换分页
    changePage(page) {
      this.$emit("changePage", page);
    },
    // 封面
    previewImgCoverImage(row){
      if (row.coverList && row.coverList.length > 0) {
        this.$previewImg({
          list: row.coverList.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),
          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    // 预览图片
    previewImg(row, index, type) {
      const urlList = type === 'cover' ? row.coverList : row.picturesList
      this.$previewImg({
        list: urlList.reduce((pre, cur) => {
          const obj = { img_url: cur };
          pre = pre.concat(obj);
          return pre;
        }, []),
        baseImgField: "img_url",
        showMute: false,
        current: index
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-content {
  max-width: 600px;
}
.img-box-item {
  position: relative;
  margin-right: 10px;
  .quick-del-icon {
    position: absolute;
    top: -8px;
    right: -8px;
  }
}
</style>