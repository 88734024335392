var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          pagination: _vm.pagination,
          loading: _vm.loading,
          columns: _vm.columns,
          "data-source": _vm.list,
          rowKey: function(record) {
            return record.id
          },
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "id",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-popover",
                    { attrs: { title: "参数" } },
                    [
                      _c(
                        "template",
                        { slot: "content" },
                        [
                          _c(
                            "a-descriptions",
                            {
                              attrs: { bordered: "", title: "", size: "small" }
                            },
                            _vm._l(row.wptExtraInfo, function(item, index) {
                              return _c(
                                "a-descriptions-item",
                                { key: index, attrs: { label: item.typeName } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.value ? item.value : "-") +
                                      " "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" " + _vm._s(row.id) + " ")
                    ],
                    2
                  )
                ],
                1
              )
            }
          },
          {
            key: "companyInfoSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.certNumber
                  ? _c("div", {}, [
                      _vm._v("评级编号：" + _vm._s(row.certNumber))
                    ])
                  : _vm._e(),
                row.companyType
                  ? _c("div", {}, [
                      _vm._v(
                        "评级公司：" +
                          _vm._s(
                            _vm.computedRatingCompanyTypeListMapText(
                              row.companyType
                            )
                          )
                      )
                    ])
                  : _vm._e(),
                row.score
                  ? _c("div", {}, [_vm._v("分数：" + _vm._s(row.score))])
                  : _vm._e()
              ])
            }
          },
          {
            key: "title",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-popover",
                    { attrs: { title: "内容" } },
                    [
                      _c("template", { slot: "content" }, [
                        _c("div", {
                          staticClass: "title-content",
                          domProps: { innerHTML: _vm._s(row.content) }
                        })
                      ]),
                      _vm._v(" " + _vm._s(row.title) + " ")
                    ],
                    2
                  )
                ],
                1
              )
            }
          },
          {
            key: "Images",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  row.isConfirm
                    ? [
                        row.coverList && row.coverList.length
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "w-100-w flex-wrap flex-start-center"
                              },
                              _vm._l(row.coverList.slice(0, 6), function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: item,
                                    staticClass: "img-box-item w-100 h-100"
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "w-100 h-100",
                                      attrs: {
                                        src: item + "/resize,w_200",
                                        alt: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.previewImg(
                                            row,
                                            index,
                                            "cover"
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "a-popconfirm",
                                      {
                                        attrs: {
                                          title: "确定删除当前图片吗？",
                                          "ok-text": "是",
                                          "cancel-text": "取消"
                                        },
                                        on: {
                                          confirm: function($event) {
                                            _vm.handleQuickDelImg(
                                              row,
                                              index,
                                              row.coverList.slice(0, 6),
                                              "cover"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticClass:
                                            "quick-del-icon font-weight-bold cur-pot",
                                          attrs: {
                                            type: "close-circle",
                                            size: "20",
                                            color: "red"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _c("img", {
                              staticStyle: {
                                "max-width": "100px",
                                "max-height": "100px"
                              },
                              attrs: {
                                src:
                                  "http://image.douquan.com/static/product-default.png",
                                alt: ""
                              }
                            })
                      ]
                    : [
                        row.picturesList && row.picturesList.length
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "w-100-w flex-wrap flex-start-center"
                              },
                              _vm._l(row.picturesList.slice(0, 6), function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: item,
                                    staticClass: "img-box-item w-100 h-100"
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "w-100 h-100",
                                      attrs: {
                                        src: item + "/resize,w_200",
                                        alt: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.previewImg(
                                            row,
                                            index,
                                            "pic"
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "a-popconfirm",
                                      {
                                        attrs: {
                                          title: "确定删除当前图片吗？",
                                          "ok-text": "是",
                                          "cancel-text": "取消"
                                        },
                                        on: {
                                          confirm: function($event) {
                                            _vm.handleQuickDelImg(
                                              row,
                                              index,
                                              row.picturesList.slice(0, 6),
                                              "pic"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticClass:
                                            "quick-del-icon font-weight-bold cur-pot",
                                          attrs: {
                                            type: "close-circle",
                                            size: "20",
                                            color: "red"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _c("img", {
                              staticStyle: {
                                "max-width": "100px",
                                "max-height": "100px"
                              },
                              attrs: {
                                src:
                                  "http://image.douquan.com/static/product-default.png",
                                alt: ""
                              }
                            })
                      ]
                ],
                2
              )
            }
          },
          {
            key: "itemDataSource",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "color-blue" }, [
                  _vm._v(
                    _vm._s(_vm.computedDataSourceTypeListMapText(row.type))
                  )
                ]),
                row.type === 3
                  ? _c(
                      "div",
                      { class: _vm.productWptStatusTypeClass[row.wptStatus] },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.computedWptStatusTypeListMapText(row.wptStatus)
                          )
                        )
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "mt-10 flex-start-center" }, [
                  _vm._v(" 是否已经确认： "),
                  _c(
                    "div",
                    { class: _vm.isConfirmStatusClassType[row.isConfirm] },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.computedIsConfirmStatusTypeListMapText(
                            row.isConfirm
                          )
                        )
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "mt-10 flex-start-center" }, [
                  _vm._v(" 是否真实： "),
                  _c(
                    "div",
                    { class: row.isReal === 1 ? "color-green" : "color-red" },
                    [_vm._v(_vm._s(row.isReal === 1 ? "真实" : "不确定"))]
                  )
                ])
              ])
            }
          },
          {
            key: "size-weight",
            fn: function(row) {
              return _c("div", {}, [
                _vm._v(" " + _vm._s(row.size) + "/" + _vm._s(row.weight) + " ")
              ])
            }
          },
          {
            key: "setup",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { margin: "5px" },
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.editItem(row)
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "确认数据？",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.removeClick(row.id, "confirm")
                        }
                      }
                    },
                    [
                      !row.isConfirm
                        ? _c(
                            "a-button",
                            {
                              staticStyle: { margin: "5px" },
                              attrs: { size: "small", type: "primary" }
                            },
                            [_vm._v("确认")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "确定删除当前数据？",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.removeClick(row.id, "del")
                        }
                      }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { margin: "5px" },
                          attrs: { size: "small", type: "danger" }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }