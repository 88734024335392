<template>
  <div class="page">
    <!-- 顶部搜索 -->
    <div class="header">
      <div class="search-view">
        <a-input
          allowClear
          v-model="params.keyword"
          size="small"
          style="width: 150px; margin: 5px"
          placeholder="关键字"
        ></a-input>
        <a-select
          allowClear
          size="small"
          style="width: 150px; margin: 5px"
          v-model="params.type"
          placeholder="数据源"
        >
          <a-select-option :value="3">WPT</a-select-option>
          <a-select-option :value="1">HX</a-select-option>
          <a-select-option :value="5">GQYD</a-select-option>
          <a-select-option :value="2">QBTT</a-select-option>
          <a-select-option :value="25">HS</a-select-option>
        </a-select>
        <a-range-picker
            :placeholder="['成交开始', '成交结束']"
            size="small"
            class="w-200 ml-10"
            @change="searchPickerChange"
        />
        <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            size="small"
            style="margin: 5px; width: 120px"
            v-model="params.isDone"
            placeholder="截拍状态"
        >
          <a-select-option :value="0">拍卖中</a-select-option>
          <a-select-option :value="1">已截拍</a-select-option>
        </a-select>
        <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            size="small"
            style="margin: 5px; width: 120px"
            v-model="params.isReal"
            placeholder="是否真实"
        >
          <a-select-option :value="0">不确定</a-select-option>
          <a-select-option :value="1">真实</a-select-option>
        </a-select>
        <a-select
            allowClear
            size="small"
            style="width: 150px; margin: 5px"
            v-model="params.isConfirm"
            placeholder="是否确认"
        >
          <a-select-option :value="1">已确认</a-select-option>
          <a-select-option :value="0">未确认</a-select-option>
        </a-select>
        <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            size="small"
            style="margin: 5px; width: 120px"
            v-model="params.priceSort"
            placeholder="价格排序"
        >
          <a-select-option :value="0">升序</a-select-option>
          <a-select-option :value="1">降序</a-select-option>
        </a-select>
        <div>
          <a-select
              allowClear
              size="small"
              style="width: 150px; margin: 5px"
              v-model="params.companyType"
              placeholder="评级公司"
          >
            <a-select-option :value="2">GB</a-select-option>
            <a-select-option :value="10">BC</a-select-option>
            <a-select-option :value="11">HX</a-select-option>
            <a-select-option :value="20">裸币</a-select-option>
            <a-select-option :value="0">无</a-select-option>
          </a-select>
          <a-select
              allowClear
              size="small"
              style="width: 150px; margin: 5px"
              v-model="params.hasCover"
              placeholder="是否有封面图"
          >
            <a-select-option :value="1">有</a-select-option>
            <a-select-option :value="0">无</a-select-option>
          </a-select>
          <a-select
              allowClear
              size="small"
              style="width: 150px; margin: 5px"
              v-model="params.hasCertNumber"
              placeholder="有无编号"
          >
            <a-select-option :value="1">有</a-select-option>
            <a-select-option :value="0">无</a-select-option>
          </a-select>
          <a-select
              :dropdownMatchSelectWidth="false"
              allowClear
              size="small"
              style="margin: 5px; width: 120px"
              v-model="params.wptStatus"
              placeholder="wpt状态"
          >
            <a-select-option value="deal">处理中</a-select-option>
            <a-select-option value="unsold">未售出</a-select-option>
            <a-select-option value="paid">支付成功</a-select-option>
            <a-select-option value="delivery">传输中</a-select-option>
            <a-select-option value="finished">交易成功</a-select-option>
          </a-select>
          <a-button
              @click="search"
              size="small"
              style="margin-left: 10px"
              type="primary"
          >搜索</a-button>
          <a-button
              @click="handleShowGetQueryPriceData"
              size="small"
              class="ml-10"
          >展示拉取数据量</a-button>
          <a-popconfirm
              title="确定要批量确认吗？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="handleAllConfirmOrDel('confirm')"
          >
            <a-button
                class="ml-10"
                size="small"
                type="primary"
                :disabled="selectedArrays.length <= 0"
            >批量确认</a-button>
          </a-popconfirm>
          <a-popconfirm
              title="确定要批量删除吗？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="handleAllConfirmOrDel('del')"
          >
            <a-button
                class="ml-10"
                size="small"
                type="danger"
                :disabled="selectedArrays.length <= 0"
            >批量删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="page-body">
      <PageTable
        :list="list"
        :loading="loading"
        :pagination="pagination"
        @changePage="changePage"
        @editItem="editItem"
        @success="getList"
        @selectTable="handleSelectTable"
        @delImg="handleDeleteImg($event)"
      ></PageTable>
    </div>
    <!-- 编辑 -->
    <TableEdit
      v-if="editDetail"
      :detail="editDetail"
      @close="closeEdit"
      @success="editSuccess"
    ></TableEdit>
<!--  获取爬取数据弹窗显示  -->
    <GetQueryPriceDataPopup ref="getQueryPriceDataPopupEl"/>
  </div>
</template>

<script>
import GetQueryPriceDataPopup from "@/views/cmsPage/coinManage/spider/_components/GetQueryPriceDataPopup/index.vue"
import PageTable from "@/views/cmsPage/coinManage/spider/PageTable.vue";
import TableEdit from "@/views/cmsPage/coinManage/spider/TableEdit.vue";
import {spiderBatchConfirm, spiderBatchDel} from "@/views/cmsPage/coinManage/_apis"
export default {
  data() {
    return {
      selectedArrays: [],
      isRequesting: false,
      list: [],
      loading: false,
      params: {
        pageSize: 20,
        pageNum: 1,
        wptStatus: undefined,
        isDone: undefined,
        isConfirm: undefined,
        isReal: undefined,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      editDetail: "",
    };
  },
  components: {
    PageTable,
    TableEdit,
    GetQueryPriceDataPopup
  },
  created() {
    this.getList()
  },
  methods: {
    /** 删除图片成功 */
    handleDeleteImg({id, index, type}) {
      this.list.forEach(el => {
        if (el.id === id) {
          if (type === 'cover') {
            el.coverList = el.coverList.filter((item, ind) => ind !== index)
          } else {
            el.picturesList = el.picturesList.filter((item, ind) => ind !== index)
          }
        }
      })
    },
    /** 选择table */
    handleSelectTable(selectedArray) {
      console.log(selectedArray, '选择')
      this.selectedArrays = JSON.parse(JSON.stringify(selectedArray))
    },
    /** 批量确认 */
    async handleAllConfirmOrDel(type) {
      this.$loading.show()
      let res
      const tempParams = {ids: this.selectedArrays.map(el =>  el.id).join(',')}
      if (type === 'confirm') {
        res = await spiderBatchConfirm(tempParams)
      } else {
        res = await spiderBatchDel(tempParams)
      }
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('已完成')
      await this.getList()
    },
    /** 展示爬取数量 */
    handleShowGetQueryPriceData() {
      this.$refs.getQueryPriceDataPopupEl.show()
    },
    // 关闭编辑
    closeEdit() {
      this.editDetail = ""
    },
    editSuccess() {
      this.closeEdit();
      this.getList();
    },
    // 编辑
    editItem(item) {
      this.editDetail = JSON.parse(JSON.stringify(item));
    },
    // 切换分页
    changePage(page) {
      const { current, pageSize } = page
      this.pagination.current = current
      this.params.pageNum = current
      this.params.pageSize = pageSize
      this.getList()
    },

    // 数据列表
    async getList() {
      this.$loading.show()
      const res = await this.axios("/dq_admin/qianbiGoodsSplider/list", {
        params: this.params,
      });
      this.$loading.hide()
      if (res.status !== '200') return;
      // 请求成功
      res.data.records = res.data.records.map((el) => {
        const wptExtraInfo = JSON.parse(el.wptExtraInfo)
        return {
          ...el,
          wptExtraInfo,
        };
      });
      const { records, total } = res.data
      this.pagination.total = total
      this.list = records
    },
    // 搜索日期筛选
    searchPickerChange(array) {
      if (array && array.length > 0) {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.params.startTime = ele._d
              .toLocaleDateString()
              .replace(/\//g, "-");
          } else {
            this.params.endTime = ele._d
              .toLocaleDateString()
              .replace(/\//g, "-");
          }
        });
      } else {
        this.params.startTime = ""
        this.params.endTime = ""
      }
    },
    // 点击搜索按钮
    search() {
      this.pagination.current = 1;
      this.params.pageNum = 1;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.page-body {
  flex: 1;
  overflow: auto;
}
</style>