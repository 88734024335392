<template>
  <a-modal
      v-model="showPopup"
      title="数据拉取量比对"
      :width="800"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancelOrConfirm"
      @ok="handleCancelOrConfirm"
  >
    <div class="popup-content">
      <div class="yesterday-beforeyesterday-data-list pr-20">
        <div class="data-title-btn data-title-btn-right">
          <a-button class="data-btn mr-20" size="small" type="primary" @click="getDataByYesterdayCoin">刷新数据</a-button>
          <span class="data-title">昨天数据量</span>
        </div>
        <div class="data-list data-list-right">
          <div class="data-item" v-for="(item, index) in yesterdayData" :key="index">
            <div class="data-item-icon mr-10">
              <a-icon
                v-if="textMapColor[computedDataBigOrSmall(index)].icon"
                :type="textMapColor[computedDataBigOrSmall(index)].icon"
                :class="textMapColor[computedDataBigOrSmall(index)].iconColor"
              />
            </div>
            <div class="data-item-num" :class="textMapColor[computedDataBigOrSmall(index)].color">{{ item }}：</div>
            <div class="data-item-title color-gray">{{ index }}</div>
          </div>
        </div>
      </div>
      <div class="yesterday-beforeyesterday-data-list pl-20">
        <div class="data-title-btn data-title-btn-left">
          <span class="data-title">前天数据量</span>
          <a-button class="data-btn ml-20" size="small" type="primary" @click="getDataByBeforeYesterdayCoin">刷新数据</a-button>
        </div>
        <div class="data-list data-list-left">
          <div class="data-item" v-for="(item, index) in beforeYesterdayData" :key="index">
            <div class="data-item-title color-gray">{{ index }}：</div>
            <div class="data-item-num">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import {getDataByTodayOrBeforeYesterday} from "@/views/cmsPage/coinManage/_apis"

export default {
  props: ['rateType'],
  computed: {
    computedDataBigOrSmall() {
      return (index) => {
        const yesterdayNum = this.yesterdayData[index]
        const beforeYesterdayNum = this.beforeYesterdayData[index]
        if (yesterdayNum > beforeYesterdayNum) {
           return 'up'
        } else if (yesterdayNum < beforeYesterdayNum) {
           return 'down'
        } else {
          return 'equal'
        }
      }
    }
  },
  data() {
    return {
      showPopup: false,
      yesterdayData: '',
      beforeYesterdayData: '',
      textMapColor: {
        up: {
          color: 'color-green',
          iconColor: 'color-green',
          icon: 'rise'
        },
        down: {
          color: 'color-red',
          iconColor: 'color-red',
          icon: 'fall'
        },
        equal: {
          color: '',
          iconColor: 'color-gray',
          icon: ''
        },
      }
    }
  },
  methods: {
    /** 展示弹窗 */
    async show(type) {
      if (type) {
        await this.getDataByDollar()
      } else {
        await this.getDataByCoin()
      }
      this.showPopup = true
    },
    /** 获取爬取钱币数据 */
    async getDataByCoin() {
      await this.getDataByYesterdayCoin()
      await this.getDataByBeforeYesterdayCoin()
    },
    /** 获取爬取银元数据 */
    async getDataByDollar() {
      await this.getDataByYesterdayDollar()
      await this.getDataByBeforeYesterdayDollar()
    },
    /** 获取数据量-昨天钱币 */
    async getDataByYesterdayCoin() {
      this.$loading.show()
      const res = await getDataByTodayOrBeforeYesterday({dayType: 'yesterday'})
      this.$loading.hide()
      if (res.status !== '200') return
      this.yesterdayData = res.data
    },
    /** 获取数据量-前天钱币 */
    async getDataByBeforeYesterdayCoin() {
      this.$loading.show()
      const res = await getDataByTodayOrBeforeYesterday({dayType: 'beforeYesterday'})
      this.$loading.hide()
      if (res.status !== '200') return
      this.beforeYesterdayData = res.data
    },
    /** TODO 获取数据量-昨天银币 */
    async getDataByYesterdayDollar() {

    },
    /** TODO 获取数据量-前天银币 */
    async getDataByBeforeYesterdayDollar() {

    },
    handleCancelOrConfirm() {
      this.showPopup = false
    }
  }
}
</script>
<style scoped lang="scss">
.data-list {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .data-item {
    margin-bottom: 20px;
    display: flex;
    font-weight: bold;
    font-size: 20px;
    .data-item-num {
      color: #0f0f0f;
    }
  }
}
.data-list-right {
  align-items: flex-end;
}
.data-list-left {
  align-items: flex-start;
}
.yesterday-beforeyesterday-data-list {
  width: 100%;
  .data-title-btn {
    width: 100%;
    display: flex;
    align-items: center;
    .data-title {
      font-weight: bold;
      font-size: 20px;
    }
  }
  .data-title-btn-left {
    justify-content: flex-start;
  }
  .data-title-btn-right {
    justify-content: flex-end;
  }
}
.popup-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.pr-20 {
  padding-right: 20px;
  border-right: 1px solid gray;
}
.pl-20 {
  padding-left: 20px;
  border-left: 1px solid gray;
}
</style>